import React from 'react';
import { Button, Input } from "@chakra-ui/react"
import { MessageSquare, Shield, SortDesc, Brain } from "lucide-react"


export default function Component() {
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-blue-50 to-indigo-100 ">
      <header className="px-4 lg:px-6 h-14 flex items-center border-b border-blue-200  bg-white sticky top-0">
        <a className="flex items-center justify-center" href="#">
          <MessageSquare className="h-6 w-6 text-blue-500" strokeWidth={2} />
          <span className="ml-2 text-2xl font-bold text-blue-500">Reply Guys</span>
        </a>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <a className="text-sm font-medium text-gray-600 hover:text-blue-500" href="#features">
            Features
          </a>
          <a className="text-sm font-medium text-gray-600 hover:text-blue-500" href="#how-it-works">
            How It Works
          </a>
          {/* <a className="text-sm font-medium text-gray-600 hover:text-blue-500" href="#pricing">
            Pricing
          </a> */}
          <a className="text-sm font-medium text-gray-600 hover:text-blue-500" href="#waitlist">
            Waitlist
          </a>
        </nav>
      </header>
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 flex justify-center items-center">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none text-white">
                  Maximize Your X Engagement
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-200 md:text-xl ">
                  Reply smarter, not harder. Use AI-powered insights to boost your X presence and learn while you engage.
                </p>
              </div>
              <div className="space-x-4">
                <Button className="bg-white text-blue-500 hover:bg-gray-100 hover:text-blue-500">Get Started</Button>
                <Button variant="outline" className="text-white border-white hover:bg-white hover:text-blue-500">Learn More</Button>
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="w-full py-12 md:py-24 lg:py-32 bg-white flex justify-center items-center">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12 text-blue-500 ">Key Features</h2>
            <div className="grid gap-10 sm:grid-cols-2 md:grid-cols-4">
              <div className="flex flex-col items-center space-y-2 p-4 rounded-lg bg-blue-50 ">
                <Shield className="h-12 w-12 text-blue-500" />
                <h3 className="text-xl font-bold text-blue-500">Verified Filter</h3>
                <p className="text-sm text-gray-600  text-center">
                  Focus on verified accounts to ensure quality interactions
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 p-4 rounded-lg bg-indigo-50 ">
                <SortDesc className="h-12 w-12 text-indigo-600 " />
                <h3 className="text-xl font-bold text-indigo-600 ">Reply Sorting</h3>
                <p className="text-sm text-gray-600  text-center">
                  Sort posts by opportunity for maximum reply visibility
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 p-4 rounded-lg bg-purple-50 ">
                <Brain className="h-12 w-12 text-purple-600 " />
                <h3 className="text-xl font-bold text-purple-600 ">AI Suggestions</h3>
                <p className="text-sm text-gray-600  text-center">
                  Get AI-generated reply suggestions based on post history
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 p-4 rounded-lg bg-pink-50 ">
                <MessageSquare className="h-12 w-12 text-pink-600 " />
                <h3 className="text-xl font-bold text-pink-600 ">Targeted Learning</h3>
                <p className="text-sm text-gray-600  text-center">
                  Learn about topics you care about while growing your account
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="how-it-works" className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-b from-blue-100 to-indigo-100 flex justify-center items-center">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12 text-blue-500 ">How It Works</h2>
            <div className="grid gap-10 sm:grid-cols-2 md:grid-cols-3">
              <div className="flex flex-col items-center space-y-2 p-4 rounded-lg bg-white  shadow-lg">
                <span className="text-3xl font-bold text-blue-500 ">1</span>
                <h3 className="text-xl font-bold text-blue-500 ">Connect Your X Account</h3>
                <p className="text-sm text-gray-600  text-center">
                  Securely a your X profile to Reply Guys
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 p-4 rounded-lg bg-white  shadow-lg">
                <span className="text-3xl font-bold text-indigo-600 ">2</span>
                <h3 className="text-xl font-bold text-indigo-600 ">Set Your Preferences</h3>
                <p className="text-sm text-gray-600  text-center">
                  Choose your interests and engagement goals
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 p-4 rounded-lg bg-white  shadow-lg">
                <span className="text-3xl font-bold text-purple-600 ">3</span>
                <h3 className="text-xl font-bold text-purple-600 ">Start Engaging</h3>
                <p className="text-sm text-gray-600  text-center">
                  Use our AI-powered tools to boost your X presence
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="waitlist"className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 flex justify-center items-center">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-white">Ready to Boost Your X Game?</h2>
                <p className="max-w-[900px] text-gray-200 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  Join Reply Guys today and start maximizing your engagement on X. Learn, grow, and connect like never before.
                </p>
              </div>
              <div className="w-full max-w-sm space-y-2">
                <form className="flex space-x-2">
                  <Input className="max-w-lg flex-1 bg-white " placeholder="Enter your email" type="email" />
                  <Button type="submit" className="bg-white text-blue-500 hover:bg-gray-100 hover:text-blue-500">Get Started</Button>
                </form>
                <p className="text-xs text-gray-200">
                  By signing up, you agree to our Terms & Conditions and Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t border-blue-200  bg-white ">
        <p className="text-xs text-gray-600 ">© 2024 Reply Guys. All rights reserved.</p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a className="text-xs text-gray-600 hover:text-blue-500 " href="#">
            Terms of Service
          </a>
          <a className="text-xs text-gray-600 hover:text-blue-500 " href="#">
            Privacy
          </a>
        </nav>
      </footer>
    </div>
  )
}